export class AjaxForm extends HTMLElement {
  private backActionButtons: HTMLElement[] = [];
  private form: HTMLFormElement | null;

  constructor() {
    super();

    this.form = this.querySelector("form");

    this.form?.addEventListener("submit", this.onSubmit);
  }

  connectedCallback() {
    this.backActionButtons = Array.from(
      this.querySelectorAll(`[data-action="back"]`),
    );

    this.backActionButtons.forEach((button) => {
      button.addEventListener("click", this.onBack);
    });
  }

  disconnectedCallback() {
    this.removeEventListener("submit", this.onSubmit);

    this.backActionButtons.forEach((button) => {
      button.removeEventListener("click", this.onBack);
    });
  }

  private onSubmit = async (event: SubmitEvent) => {
    event.preventDefault();

    if (this.form === null) {
      return;
    }

    try {
      this.classList.add("is-sent");

      const response = await fetch(this.form.action, {
        method: this.form.method,
        body: new FormData(this.form),
      });

      this.classList.toggle("is-success", response.ok === true);
      this.classList.toggle("is-error", response.ok === false);
    } catch (error) {
      this.classList.add("is-error");
    }
  };

  private onBack = () => {
    this.classList.remove("is-sent", "is-success", "is-error");
  };
}
